/* global jQuery*/

window.refreshNavMenu = undefined;

jQuery(function($) {
  'use strict';

  // activate navBar according to current location:
  //   add 'active' class to each li parent of the matching <a>, up to #navBar
  $('#navBar li').removeClass('active');
  $('#navBar li a[href="' + location.pathname + '"]').parents('#navBar li').addClass('active');
  $('#navBar li a[href="' + location.protocol + '//' + location.host + location.pathname + '"]').parents('#navBar li').addClass('active');

  window.refreshNavMenu = function() {
    $('#navMenu a.list-group-item').removeClass('active');

    var el;

    el = $('#navMenu a.list-group-item[href="' + location.hash + '"]');
    if (el && el.length !== 0) {
      el.addClass('active');
      return;
    }
    el = $('#navMenu a.list-group-item[href="' + location.pathname + location.hash + '"]');
    if (el && el.length !== 0) {
      el.addClass('active');
      return;
    }

    el = $('#navMenu a.list-group-item[href="' + location.pathname + '"]');
    if (el && el.length !== 0) {
      el.addClass('active');
      return;
    }

    el = $('#navMenu a.list-group-item[href="' + location.protocol + '//' + location.host + location.pathname + location.hash + '"]');
    if (el && el.length !== 0) {
      el.addClass('active');
      return;
    }

    el = $('#navMenu a.list-group-item[href="' + location.protocol + '//' + location.host + location.pathname + '"]');
    if (el && el.length !== 0) {
      el.addClass('active');
      return;
    }

    var h = location.hash;
    if (!h)
      return;

    var hs = h.split('/');
    for (var i = 0, l = h.length; i < l; ++i) {
      h = hs.slice(0, i).join('/');

      el = $('#navMenu a.list-group-item[href="' + h + '"]');
      if (el && el.length !== 0) {
        el.addClass('active');
        return;
      }

      el = $('#navMenu a.list-group-item[href="' + location.pathname + h + '"]');
      if (el && el.length !== 0) {
        el.addClass('active');
        return;
      }

      el = $('#navMenu a.list-group-item[href="' + location.protocol + '//' + location.host + location.pathname + h + '"]');
      if (el && el.length !== 0) {
        el.addClass('active');
        return;
      }
    }
  };
  window.refreshNavMenu();

  $('#main').on('click', 'a[data-toggle=collapse]', function() {
    $(this).find('i').first().toggleClass('fa-caret-square-o-down fa-caret-square-o-right');
  });

  $('.locale').click(function(e) {
    e.target.href = e.target.href + location.hash;
  });

  // show back to top link when scrolling down
  $(window).scroll(function() {
    if($(this).scrollTop())
      $('#backToTop').fadeIn();
    else
      $('#backToTop').fadeOut();
  });

  if (window.bootlint)
    window.bootlint.showLintReportForCurrentDocument([]);

});
